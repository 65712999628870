import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = environment.title;

  constructor(
    private metaTitle: Title,
    private meta: Meta
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.metaTitle.setTitle(`${environment.title} | ${environment.subtitle}`);
    this.meta.addTags([
      { name: 'description', content: `${environment.description}.` },
      { name: 'keywords', content: environment.keywords },
    ]);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { getEnvironment } from '../../../../environment/environment';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule
  ]
})


export class FooterComponent implements OnInit {

  currentYear = new Date().getFullYear();
  year?: string;
  title?: string;

  env = getEnvironment();

  constructor(
    private router: Router
  ) { }
    
  ngOnInit() {
    this.year = this.env.yearProyect;
    this.title = this.env.title;
  }

  irInicio() {
    this.router.navigateByUrl('/');
    // this.navController.navigateRoot('inicio');
  }

}

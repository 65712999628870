<div class="fixed top-0 left-0 w-full h-full max-h-full overflow-x-hidden overflow-y-auto">
	<div class="w-full min-h-full h-auto bg-primary grid md2:grid-cols-[minmax(0,65%)_minmax(0,35%)] md:grid-cols-[minmax(0,70%)_minmax(0,30%)] grid-cols-1">
		<div class="w-full md:order-1 order-2 min-h-full flex flex flex-col items-center justify-center p-6 relative -mt-[5rem]">
			<div class="absolute top-0 left-0 w-full h-full z-10 md:bg-[url('/assets/img/figure.svg')] bg-[url('/assets/img/figure-resp.svg')] bg-cover bg-no-repeat md:bg-[right_center] bg-[center_top] drop-shadow-[2px_0px_14px_rgba(0,0,0,0.2)]"></div>
			<div class="block z-20 text-center md:pt-0 pt-[10rem] md:mb-[-6rem]">
				<h1 class="block m-0 md:text-[7rem] text-[5rem] leading-tight text-primary font-bold">{{ error_code }}</h1>
				<h2 class="block m-0 text-4xl font-semibold text-primary">Error</h2>
				<h3 class="block m-0 md:text-3xl text-2xl font-semibold text-[color:var(--second-color)]">{{ error_code }} - {{ error_detail }}</h3>
				<p class="block md:text-xl text-lg my-4 font-semibold text-[color:var(--third-color)]">{{ error_description }}</p>
				<button routerDirection="root" routerLink="/" class="inline-block mt-5 bg-primary hover:bg-[color:var(--second-color)] py-4 px-8 md:text-xl text-lg rounded shadow transition-all ease-in-out duration-300 text-white leading-none font-semibold" style="text-shadow: 0 0 2px rgba(0,0,0,0.45);">Volver al inicio</button>
			</div>
		</div>
		<div class="w-full md:order-2 order-1 p-6 min-h-full flex items-center md:justify-center">
			<img class="md:w-[250px] w-[120px] max-w-full md:mx-auto" [src]="env.imgLogo" alt="LOGO">
		</div>
	</div>
</div>
